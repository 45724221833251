import ActionsSection from "@/components/ActionsSection";
import AlertDialogWithActions from "@/components/AlertDialogWithActions";
import ApproveRejectForm from "@/components/ApproveRejectForm";
import OrgDetails from "@/components/OrgDetails";
import UploadWidget from "@/components/UploadWidget";
import DashboardOutlet from "@/components/templates/DashboardOutlet";
import { TypographyH4 } from "@/components/ui/Typography";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import MultiSelect from "@/components/ui/multi-select";
import { Textarea } from "@/components/ui/textarea";
import { useCurrentUserContext } from "@/lib/context/CurrentUserContext";
import useLicenseApplicationAddCustomAgreement from "@/lib/hooks/mutations/useLicenseApplicationAddCustomAgreement";
import useLicenseApplicationAddPayment from "@/lib/hooks/mutations/useLicenseApplicationAddPayment";
import useLicenseApplicationApprove from "@/lib/hooks/mutations/useLicenseApplicationApprove";
import useLicenseApplicationReject from "@/lib/hooks/mutations/useLicenseApplicationReject";
import useOrgSuAddNote from "@/lib/hooks/mutations/useOrgSuAddNote";
import useProviderLicenseAgreementReplace from "@/lib/hooks/mutations/useProviderLicenseAgreementReplace";
import useProviderLicenseReinstate from "@/lib/hooks/mutations/useProviderLicenseReinstate";
import useProviderLicenseSuspend from "@/lib/hooks/mutations/useProviderLicenseSuspend";
import useProviderSetMarkingsDlPrivilegeLevel from "@/lib/hooks/mutations/useProviderSetMarkingsDlPrivilegeLevel";
import useProviderSetPreEngagementCheckLevel from "@/lib/hooks/mutations/useProviderSetPreEngagementCheckLevel";
import {
  earlyMarkingsDownloadsLabelMap,
  licenseApplicationLabelMap,
  preEngagementCheckLabelMap,
} from "@/lib/label-maps";
import { uploadSchemaRequired, zStringRequired } from "@/lib/schemas/helpers";
import { RouterOutputs, trpc } from "@/lib/trpc";
import { zodResolver } from "@hookform/resolvers/zod";
import { InfoIcon } from "lucide-react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as z from "zod";
import { MarkingsDownloadPrivilegeLevel } from "../../../crates/e-licensing/bindings/MarkingsDownloadPrivilegeLevel";
import { PreEngagementCheckLevel } from "../../../crates/e-licensing/bindings/PreEngagementCheckLevel";
import signLicensingPDF from "@/lib/sign-pdf";

export default function OrgPage() {
  const { orgId } = useParams<{ orgId: string }>();
  if (!orgId) throw new Error("No such org");
  const addNoteMutation = useOrgSuAddNote();
  const currentUser = useCurrentUserContext();

  if (!currentUser.super_user_role) {
    throw new Error("Not Found");
  }
  const orgQuery = trpc.getOrgById.useQuery(orgId);

  return (
    <DashboardOutlet title="View/Edit Organization">
      {orgQuery.isLoading ? (
        <div>Loading...</div>
      ) : orgQuery.isError || !orgQuery.data ? (
        <div>Error loading org.</div>
      ) : (
        <>
          <OrgStatus org={orgQuery.data} />
          <OrgDetails
            org={orgQuery.data}
            actionsComponent={<OrgOrgIdActions org={orgQuery.data} />}
            companyProfile={{ isEditable: true }}
            licenseApplication={{ isEditable: false }}
            notes={{
              addNote: (note) =>
                addNoteMutation.mutate({ note, org_id: orgQuery.data!.org_id }),
            }}
          />
        </>
      )}
    </DashboardOutlet>
  );
}

function OrgOrgIdActions({
  org,
}: {
  org: NonNullable<RouterOutputs["getOrgById"]>;
}) {
  return (
    <div className="space-y-12 max-w-2xl">
      <PaymentOverrideSection org={org} />
      <ProviderApplicationSection org={org} />
      <LicensingAgreementSection org={org} />
      <PrivilegesSection org={org} />
      <StatusSection org={org} />
    </div>
  );
}

function PaymentOverrideSection({
  org,
}: {
  org: NonNullable<RouterOutputs["getOrgById"]>;
}) {
  const addPaymentMutation = useLicenseApplicationAddPayment();
  const canAddPaymentOverride = [
    "CustomLicenseAgreementAdded",
    "StandardLicenseAgreementAccepted",
  ].includes(org.license_application?.status || "");
  if (!canAddPaymentOverride) return null;

  function handleAddPayment() {
    addPaymentMutation.mutate({
      license_application_id: org.license_application!.license_application_id,
      payment_details: "AdminOverride",
    });
  }
  return (
    <ActionsSection title="Payment Override">
      <AlertDialogWithActions
        title="Add Payment Override"
        action={handleAddPayment}
        triggerText="Add Payment Override"
        description="Are you sure you want to add a payment override? This will activate the provider."
      />
    </ActionsSection>
  );
}

function ProviderApplicationSection({
  org,
}: {
  org: NonNullable<RouterOutputs["getOrgById"]>;
}) {
  const approveApplicationMutation = useLicenseApplicationApprove(org.org_id);
  const rejectApplicationMutation = useLicenseApplicationReject(org.org_id);
  const providerId = org.provider_details?.provider_id;
  const orgIsApplicant =
    !providerId &&
    !!org.license_application &&
    !["Complete", "ApplicationApproved"].includes(
      org.license_application.status
    );
  if (!orgIsApplicant) return null;

  return (
    <ActionsSection title="Provider Application">
      <ApproveRejectForm
        rejectionReason={org.license_application!.rejection?.rejection_reason}
        sendApproval={() =>
          approveApplicationMutation.mutate({
            license_application_id:
              org.license_application!.license_application_id,
          })
        }
        sendRejection={(reason: string) =>
          rejectApplicationMutation.mutate({
            license_application_id:
              org.license_application!.license_application_id,
            reason,
          })
        }
      />
    </ActionsSection>
  );
}

function PrivilegesSection({
  org,
}: {
  org: NonNullable<RouterOutputs["getOrgById"]>;
}) {
  const changeMarkingsPrivilegesForm = useForm({
    resolver: zodResolver(z.object({ level: zStringRequired })),
    values: {
      level: org.provider_details?.markings_dl_privilege_level
        ? earlyMarkingsDownloadsLabelMap[
            org.provider_details?.markings_dl_privilege_level
          ]
        : "",
    },
  });
  const changePreEngagementPrivilegesForm = useForm({
    resolver: zodResolver(z.object({ level: zStringRequired })),
    values: {
      level: org.provider_details?.pre_engagement_check_level
        ? preEngagementCheckLabelMap[
            org.provider_details?.pre_engagement_check_level
          ]
        : "",
    },
  });

  const markingsPrivilegeMutation = useProviderSetMarkingsDlPrivilegeLevel(
    org.org_id
  );
  const handleChangeMarkingsPrivilege =
    changeMarkingsPrivilegesForm.handleSubmit(async ({ level: label }) => {
      const level = Object.entries(earlyMarkingsDownloadsLabelMap).find(
        ([_, value]) => value === label
      )![0];
      markingsPrivilegeMutation.mutate({
        level: level as MarkingsDownloadPrivilegeLevel,
        provider_id: org.provider_details!.provider_id,
      });
    });
  const preEngagementCheckMutation = useProviderSetPreEngagementCheckLevel(
    org.org_id
  );

  if (!org.provider_details) return null;

  const handleChangePreEngagementPrivilege =
    changePreEngagementPrivilegesForm.handleSubmit(async ({ level: label }) => {
      const level = Object.entries(preEngagementCheckLabelMap).find(
        ([_, value]) => value === label
      )![0];
      preEngagementCheckMutation.mutate({
        level: level as PreEngagementCheckLevel,
        provider_id: org.provider_details!.provider_id,
      });
    });
  return (
    <ActionsSection title="Privileges">
      <Form {...changeMarkingsPrivilegesForm}>
        <form onSubmit={handleChangeMarkingsPrivilege}>
          <FormField
            name="level"
            control={changeMarkingsPrivilegesForm.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Early Markings Downloads Level</FormLabel>
                <FormControl>
                  <MultiSelect
                    field={field}
                    options={Object.values(earlyMarkingsDownloadsLabelMap)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            type="submit"
            disabled={
              !changeMarkingsPrivilegesForm.formState.isDirty ||
              markingsPrivilegeMutation.isLoading
            }
            className="mt-2"
          >
            Update Level
          </Button>
        </form>
      </Form>
      <Form {...changePreEngagementPrivilegesForm}>
        <form onSubmit={handleChangePreEngagementPrivilege}>
          <FormField
            name="level"
            control={changePreEngagementPrivilegesForm.control}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Pre-engagement Check Level</FormLabel>
                <FormControl>
                  <MultiSelect
                    field={field}
                    options={Object.values(preEngagementCheckLabelMap)}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            type="submit"
            disabled={
              !changePreEngagementPrivilegesForm.formState.isDirty ||
              preEngagementCheckMutation.isLoading
            }
            className="mt-2"
          >
            Update Level
          </Button>
        </form>
      </Form>
    </ActionsSection>
  );
}

function LicensingAgreementSection({
  org,
}: {
  org: NonNullable<RouterOutputs["getOrgById"]>;
}) {
  const {
    isLoading,
    data: standardAgreement,
    isError,
  } = trpc.retrieveLicensingAgreementAcceptance.useQuery(org.org_id);
  // make sure not to use their application agreement if they're already a provider
  const customLicenseAgreementFileDetails = org.provider_details
    ? org.provider_details.custom_license_agreement?.file_details
    : org.license_application?.custom_license_agreement?.file_details;

  const addCustomAgreementToApplicationMutation =
    useLicenseApplicationAddCustomAgreement(org.org_id);
  const addCustomAgreementToProviderMutation =
    useProviderLicenseAgreementReplace(org.org_id);
  const customAgreementForm = useForm({
    resolver: zodResolver(z.object({ customAgreement: uploadSchemaRequired })),
    values: {
      customAgreement:
        standardAgreement === null ? customLicenseAgreementFileDetails : null,
    },
  });

  if (!org.license_application) return null;

  const customAgreementField = customAgreementForm.watch("customAgreement");

  const handleSubmitCustomAgreementForm = customAgreementForm.handleSubmit(
    ({ customAgreement }) => {
      if (org.provider_details) {
        addCustomAgreementToProviderMutation.mutate({
          provider_id: org.provider_details.provider_id,
          new_license_agreement: {
            Custom: {
              file_details: customAgreement!,
            },
          },
        });
      } else {
        addCustomAgreementToApplicationMutation.mutate({
          license_application_id:
            org.license_application!.license_application_id,
          custom_agreement: {
            file_details: customAgreement!,
          },
        });
      }
    }
  );

  return (
    <ActionsSection title="Licensing Agreement">
      {isLoading ? (
        <div>Loading...</div>
      ) : isError ? (
        <div>
          Something went wrong loading the licensing agreement, please refresh
          the page and try again
        </div>
      ) : (
        <div className="space-y-12">
          {!!standardAgreement && (
            <div>
              <p>
                <span className="font-semibold">Licensing Agreement: </span>
                Standard
              </p>
              <Button
                onClick={async () => await signLicensingPDF(standardAgreement)}
              >
                Download
              </Button>
            </div>
          )}
          <Form {...customAgreementForm}>
            <form onSubmit={handleSubmitCustomAgreementForm}>
              <FormField
                name="customAgreement"
                control={customAgreementForm.control}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Custom Agreement</FormLabel>
                    <FormControl>
                      <UploadWidget
                        value={customAgreementField}
                        onSuccess={field.onChange}
                        tag="custom-agreement"
                      />
                    </FormControl>
                    <FormDescription>
                      Maximum file size: 10mb. Supported formats: pdf, docx,
                      png, jpg, wepb, avif.
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button
                type="submit"
                className="mt-6"
                disabled={
                  !customAgreementForm.formState.isDirty ||
                  addCustomAgreementToApplicationMutation.isLoading ||
                  addCustomAgreementToProviderMutation.isLoading
                }
              >
                {customLicenseAgreementFileDetails
                  ? "Replace Licensing Agreement"
                  : "Add Licensing Agreement"}
              </Button>
            </form>
          </Form>
        </div>
      )}
    </ActionsSection>
  );
}

function StatusSection({
  org,
}: {
  org: NonNullable<RouterOutputs["getOrgById"]>;
}) {
  const suspendOrgForm = useForm({
    resolver: zodResolver(z.object({ reason: zStringRequired })),
    defaultValues: { reason: "" },
  });
  const reinstateOrgForm = useForm({
    resolver: zodResolver(z.object({ reason: zStringRequired })),
    defaultValues: { reason: "" },
  });
  const suspendMutation = useProviderLicenseSuspend(org.org_id);
  const handleSubmitSuspensionForm = suspendOrgForm.handleSubmit(
    async ({ reason }) => {
      suspendMutation.mutate({
        provider_id: org.provider_details!.provider_id,
        reason,
      });
    }
  );

  const reinstateMutation = useProviderLicenseReinstate(org.org_id);
  const handleSubmitReinstatementForm = reinstateOrgForm.handleSubmit(
    async ({ reason }) => {
      reinstateMutation.mutate({
        provider_id: org.provider_details!.provider_id,
        reason,
      });
    }
  );

  if (!org.provider_details) return null;

  return (
    <ActionsSection title="Status">
      {org.provider_details?.suspension ? (
        <div className="space-y-12">
          <div className="space-y-6">
            <TypographyH4 className="text-destructive/70">
              Organization is suspended
            </TypographyH4>
            <p>Reason for suspension:</p>
            <p className="whitespace-pre-wrap">
              {org.provider_details.suspension.suspension_reason}
            </p>
          </div>
          <Form {...reinstateOrgForm}>
            <form className="space-y-2">
              <FormField
                name="reason"
                control={reinstateOrgForm.control}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Reason For Reinstatement</FormLabel>
                      <FormControl>
                        <Textarea {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <Button
                type="submit"
                onClick={handleSubmitReinstatementForm}
                disabled={
                  !reinstateOrgForm.formState.isDirty ||
                  reinstateMutation.isLoading
                }
              >
                Reinstate
              </Button>
            </form>
          </Form>
        </div>
      ) : (
        <div className="space-y-6">
          <TypographyH4>Suspend Org</TypographyH4>
          <Form {...suspendOrgForm}>
            <form className="space-y-2">
              <FormField
                name="reason"
                control={suspendOrgForm.control}
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>Reason For Suspension</FormLabel>
                      <FormControl>
                        <Textarea {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <Button
                type="submit"
                variant="destructive"
                onClick={handleSubmitSuspensionForm}
                disabled={
                  !suspendOrgForm.formState.isDirty || suspendMutation.isLoading
                }
              >
                Suspend
              </Button>
            </form>
          </Form>
        </div>
      )}
    </ActionsSection>
  );
}

function OrgStatus({ org }: { org: NonNullable<RouterOutputs["getOrgById"]> }) {
  const providerId = org.provider_details?.provider_id;
  const orgIsApplicant =
    !providerId &&
    !!org.license_application &&
    org.license_application.status !== "Complete";

  return (
    <Alert className="max-w-md mb-12">
      <InfoIcon className="h-4 w-4" />
      <AlertTitle>Status</AlertTitle>
      <AlertDescription>
        {!!providerId && (
          <p>
            Organization is a provider with ID:{" "}
            <span className="font-semibold">{providerId}</span>
          </p>
        )}
        {orgIsApplicant && (
          <p>
            Organization is an applicant with status:{" "}
            <span className="font-semibold">
              {licenseApplicationLabelMap[org.license_application!.status]}
            </span>
          </p>
        )}
        {!org.license_application && <p>License application not started</p>}
        {org.license_application?.status === "ApplicationRejected" && (
          <div className="mt-3 space-y-1">
            <p className="font-semibold">Rejection reason:</p>
            <p className="whitespace-pre-wrap">
              {org.license_application?.rejection?.rejection_reason}
            </p>
          </div>
        )}
      </AlertDescription>
    </Alert>
  );
}
